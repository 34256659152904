import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import tw from "twin.macro"
import { WikiTemplateInterface } from "../@types/templates/wiki"
import HCard from "../components/hCard"

const WikiTemplate: React.FC<WikiTemplateInterface> = ({ data }) => {
  const Article = styled.article`
    ${tw`
      flex flex-col
      xl:w-8/12 xl:self-center
      `}
  `

  const Title = styled.h1`
    ${tw`
    text-5xl font-headers leading-snug
    `}
  `

  const TitleBlock = styled.section`
    ${tw`border-b pb-4`};
  `

  const Content = styled.section`
    a {
      ${tw`font-semibold text-linkcolor hover:text-linkhovercolor`}
    }

    p,
    ul,
    ol,
    blockquote,
    picture,
    table {
      ${tw`pb-4`};
    }
    ,
    div.gatsby-highlight {
      ${tw`mt-2`}
    }

    hr {
      ${tw`mt-4 mb-4`}
    }

    blockquote {
      ${tw`border-l-8 m-2 p-2 border-linkcolor`};
      background-color: white;
    }

    h1 {
      ${tw`text-4xl font-contentheader mt-6`}
    }
    ,
    h2 {
      ${tw`text-3xl font-contentheader mt-6`}
    }
    ,
    h3 {
      ${tw`text-2xl font-contentheader mt-6`}
    }
    ,
    h4 {
      ${tw`text-xl font-contentheader mt-6`}
    }
    ,
    h5 {
      ${tw`text-lg font-contentheader mt-6`}
    }
    ,
    h6 {
      ${tw`text-base font-contentheader mt-6`}
    }

    strong {
      ${tw`font-bold`}
    }

    table > thead > tr,
    table > tbody > tr:not(:last-of-type) {
      ${tw`border-b`}
    }
    table > thead > tr > th:not(:first-of-type):not(:last-of-type),
    table > tbody > tr > td:not(:first-of-type):not(:last-of-type) {
      ${tw`border-l border-r`}
    }
    table > thead > tr > th,
    table > tbody > tr > td {
      ${tw`pl-2 pr-2`}
    }

    /** Prevent links in cells from being too close together to avoid audit warnings for links inside cells */
    th,
    td {
      ${tw`pt-2 pb-2`}
    }

    /**
     * Enable responsive overflow
     */
    table {
      ${tw`overflow-auto block`};
    }

    div.footnotes {
      ${tw`font-hairline text-sm`}
    }

    ul {
      ${tw`list-disc list-inside`}
    }

    li {
      ${tw`pl-2`}
    }
  `

  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        type={"article"}
        description={data.mdx.excerpt}
      />
      <Article className={"h-entry"}>
        <HCard className={"p-author"} />
        <TitleBlock>
          <Title className={"p-name"}>
            <a className={"u-url"} href={data.mdx.fields.permalink}>
              {data.mdx.frontmatter.title}
            </a>
          </Title>
        </TitleBlock>
        <Content className={"e-content"}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </Content>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
      }
      excerpt
      fields {
        permalink
      }
    }
  }
`

export default WikiTemplate
